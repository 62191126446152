<template>
  <div>
    <top-nav :showLogo="false" :title="$t('转账')"></top-nav>

    <div class="top-bg">
      <div class="title">
        <p>{{ $t('转账') }}</p>
        <p class="subtitle">
          {{ $t('转账明细') }}
        </p>
      </div>
      <img src="../../assets/image/rwa/top-bg.png">
    </div>
    <div class="container">
      <div class="actual-acquisition">
        <div style="float: right" v-if="formData.coin == 'cny'">
          {{ $t('可用') }}：{{ userInfo ? userInfo.cny : '-' }} {{ $t('拔草值') }}
        </div>
        <div style="float: right" v-else>
          {{ $t('可用') }}： {{ userInfo ? userInfo.forage : '-' }} {{ $t('种草值') }}
        </div>
      </div>

      <div class="input-cont">
        <div class="title" @click="showSelectCurrency = true">
          {{ formData.coin == 'cny' ? $t('拔草值') : $t('种草值') }}
          <img class="lower-triangle-icon" src="../../assets/image/rwa/lower-triangle-icon.png">
        </div>
        <div class="input-div">
          <input type="text" @focus="promptAmountText = ''" v-model="formData.amount"
                 :placeholder="$t('请输入')"/>
          <img class="empty-icon" v-if="formData.amount" @click="emptyValue('amount')"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
        <div class="all-but">
          <span @click="allAmount">{{ $t('全部') }}</span>
        </div>
      </div>
      <div class="prompt-cont">
        {{ promptAmountText }}
      </div>
      <div class="input-cont">
        <div class="title">UID</div>
        <div class="input-div">
          <input type="text" @focus="promptIdText = ''" v-model="formData.id"
                 :placeholder="$t('请输入')"/>
          <img class="empty-icon" v-if="formData.id" @click="emptyValue('id')"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
      </div>
      <div class="prompt-cont">
        {{ promptIdText }}
      </div>
      <div class="input-cont">
        <div class="title">{{ $t('密码') }}</div>
        <div class="input-div">
          <input type="password" @focus="promptPwbText = ''" v-model="formData.pwd"
                 :placeholder="$t('请输入')"/>
          <img class="empty-icon" v-if="formData.pwd" @click="emptyValue('pwd')"
               src="../../assets/image/rwa/empty-icon.png">
        </div>
      </div>
      <div class="edit-pwd" @click="onShowSetPwd">
        {{ userInfo.trade_pwd ? $t('修改密码') : $t('设置密码') }}
      </div>
      <div class="prompt-cont">
        {{ promptPwbText }}
      </div>


      <div class="bottom-cont">
        <div class="prompt-cont">
          <img class="prompt-icon" src="../../assets/image/rwa/prompt-icon.png">
          {{ $t('非关系账户无法转账') }}
        </div>
        <router-link to="transferRecord">
          <div class="order-but">
            <img class="order-icon" src="../../assets/image/rwa/order-icon2.png">
          </div>
        </router-link>

        <div class="confirm-but" @click="postTransfer">
          {{ $t('确定') }}
        </div>
      </div>
    </div>


    <van-popup v-model="showSelectCurrency" position="bottom">
      <div class="currency-cont">
        <div class="close-icon">
          <img @click="showSelectCurrency = !showSelectCurrency" src="../../assets/image/popup/close-icon.png">
        </div>
        <div class="lang-list">
          <div class="lang-item">
            <div class="lang">
              <div>{{ $t('拔草值') }}</div>
            </div>
            <div class="checkbox-cont">
              <van-checkbox class="checkbox"
                            v-model="checkboxCny"
                            @click="selectCoin('cny')"
                            checked-color="#fff">

              </van-checkbox>
            </div>

          </div>
          <div class="lang-item">
            <div class="lang">
              <div>{{ $t('种草值') }}</div>
            </div>
            <div class="checkbox-cont">
              <van-checkbox class="checkbox"
                            @click="selectCoin('forage')"
                            v-model="checkboxForage"
                            checked-color="#fff">

              </van-checkbox>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <div class="title">{{ $t('转账成功') }}</div>
          <div class="text">{{ $t('向') }}UID{{ formData.id }}
            <br/>
            {{ $t('转入') }} {{ formData.amount }}
            {{ formData.coin == 'cny' ? $t('拔草值') : $t('种草值') }}
          </div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

    <van-popup v-model="showSetPwd" :close-on-click-overlay="false">
      <div class="set-pwd-cont">
        <div class="title">
          <span v-if="userInfo.trade_pwd">{{ $t('修改密码') }}</span>
          <span v-else>{{ $t('请设置密码') }}</span>
        </div>
        <div v-if="userInfo.trade_pwd">
          <div class="input-cont">
            <input :type="type1" @focus="prompt1 = ''" maxlength="6" v-model="formPwd.old_pwd"
                   :placeholder="$t('原密码')"/>
            <img class="show-icon" v-if="type1 == 'text'" @click="type1 = 'password'"
                 src="../../assets/image/index/show_icon.png">
            <img class="show-icon" v-else @click="type1 = 'text'" src="../../assets/image/index/not_show_icon.png">
          </div>
          <div class="error-info">
            {{ prompt1 }}
          </div>
        </div>

        <div class="input-cont">
          <input :type="type2" @focus="prompt2 = ''" maxlength="6" v-model="formPwd.pwd" :placeholder="$t('设置密码')"/>
          <img class="show-icon" v-if="type2 == 'text'" @click="type2 = 'password'"
               src="../../assets/image/index/show_icon.png">
          <img class="show-icon" v-else @click="type2 = 'text'" src="../../assets/image/index/not_show_icon.png">
        </div>
        <div class="error-info">
          {{ prompt2 }}
        </div>
        <div class="input-cont">
          <input :type="type3" maxlength="6" @focus="prompt3 = ''" v-model="formPwd.c_pwd" :placeholder="$t('再次输入密码')"/>
          <img class="show-icon" v-if="type3 == 'text'" @click="type3 = 'password'"
               src="../../assets/image/index/show_icon.png">
          <img class="show-icon" v-else @click="type3 = 'text'" src="../../assets/image/index/not_show_icon.png">
        </div>
        <div class="error-info">
          {{ prompt3 }}
        </div>
        <div class="notes">
          {{ $t('如忘记密码请联系管理员') }}
        </div>

        <div class="operate-cont">
          <div class="operate-but" style="float: left" @click="showSetPwd= false">{{ $t('取消') }}</div>
          <div class="operate-but ok" style="float: right" @click="postTradePwd">{{ $t('确定') }}</div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {mapState} from "vuex";
import {postTransfer, postTradePwd} from "../../api";

export default {
  name: "transfer",
  components: {TopNav},
  data() {
    return {
      showSetPwd: false,
      showSelectCurrency: false,
      checkboxCny: true,
      checkboxForage: false,
      formData: {
        "id": '',
        "coin": "cny", //cny 拔草值 forage种草值
        "amount": '',
        "pwd": '',
      },
      promptAmountText: '',
      promptIdText: '',
      promptPwbText: '',
      showOperationSuccess: false,

      type1: 'password',
      type2: 'password',
      type3: 'password',
      formPwd: {
        old_pwd: '',//原密码
        pwd: '',//密码
        c_pwd: '',//确认密码
      },
      prompt1: '',
      prompt2: '',
      prompt3: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.app.userInfo,
    })
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    onShowSetPwd() {
      this.showSetPwd = true
      this.formPwd = {
        old_pwd: '',
        pwd: '',
        c_pwd: '',
      }
      this.prompt1 = '';
      this.prompt2 = '';
      this.prompt3 = '';
    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.formData.amount = ''
        this.formData.id = ''
        this.formData.pwd = ''
      }
    },
    postTradePwd() {
      let that = this;
      if (that.formPwd.old_pwd.length < 1 && that.userInfo.trade_pwd) {
        that.prompt1 = that.$t('请输入原密码')
        return
      } else if (that.formPwd.pwd.length < 6) {
        that.prompt2 = that.$t('请输入6位密码')
        return
      } else if (that.formPwd.pwd != that.formPwd.c_pwd) {
        that.prompt3 = that.$t('两次密码不一致')
        return
      }
      that.prompt1 = ''
      that.prompt2 = ''
      that.prompt3 = ''
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      postTradePwd(that.formPwd)
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              setTimeout(() => {
                that.$toast(that.$t("设置成功"));
              }, 500)
              that.getUserInfo()
              that.showSetPwd = false

            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    postTransfer() {
      let that = this;
      if (that.formData.amount.length == 0) {
        that.promptAmountText = that.$t('请输入正确的数量')
        return
      } else if (!that.formData.id) {
        that.promptIdText = that.$t('请输入正确的UID')
        return
      } else if (!that.formData.pwd) {
        that.promptPwbText = that.$t('请输入密码')
        return
      }
      that.promptAmountText = ''
      that.promptIdText = ''
      that.promptPwbText = ''

      this.$toast.loading({
        message: '',
        duration: 0,
      });
      postTransfer(this.formData)
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              that.getUserInfo()
              that.onShowActivationSuccess()
            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    allAmount() {
      if (this.formData.coin == 'cny') {
        this.formData.amount = this.userInfo.cny
      } else {
        this.formData.amount = this.userInfo.forage
      }
    },
    emptyValue(key) {
      this.formData[key] = ''
      if (key == 'coin') {
        this.promptAmountText = ''
      } else if (key == 'id') {
        this.promptIdText = ''
      } else if (key == 'pwd') {
        this.promptPwbText = ''
      }
    },
    selectCoin(coin) {
      if (coin == 'cny') {
        this.checkboxCny = true
        this.checkboxForage = false
      } else {
        this.checkboxForage = true
        this.checkboxCny = false
      }
      this.formData.coin = coin
      this.formData.amount = ''
      this.showSelectCurrency = false
    },
    getUserInfo() {
      this.$store.dispatch('app/getUserInfo')
          .then((res) => {
            console.log(res);
            if (res.code == 200 && !res.data.trade_pwd) {
              this.showSetPwd = true;
            }
          })
          .finally(() => {
            // this.$toast.clear()
          })
    },
  }
}
</script>


<style scoped lang="less">
.top-bg {
  position: relative;

  .title {
    position: absolute;
    left: 2.29rem;
    bottom: 1.35rem;
    font-size: 1.43rem;
    color: #E7BD71;
  }

  .subtitle {
    font-size: 0.93rem;
    color: #6E6045;
  }

  img {
    width: 100%;
  }
}

.container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  padding: 0.9rem 1rem;
  background: linear-gradient(180deg, #E8BD70 0%, #EDD185 100%);
  border-radius: 1rem 1rem 0 0;
}

.input-cont {
  padding: 1rem 1.35rem;
  background: #FFF8EA;
  border-radius: 0.71rem;
  margin-top: 0.68rem;

  .title {
    float: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lower-triangle-icon {
    position: relative;
    padding-left: 0.1rem;
    top: -0.1rem;
    width: 0.64rem;
  }

  .input-div {
    text-align: right;
    line-height: 1.29rem;

    input {
      width: 50%;
      text-align: right;
      font-size: 1rem;
      color: #333333;
      border: none;
      background-color: transparent;
    }

    input::-webkit-input-placeholder {
      color: #999999;
    }

    span {
      font-size: 0.79rem;
      color: #666;
    }

    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }
}

.prompt-cont {
  width: 90%;
  margin: auto;
  color: #E64937;
  font-size: 0.86rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.all-but {
  text-align: right;
  padding-top: 0.8rem;
  font-size: 0.79rem;
  color: #987534;
}

.actual-acquisition {
  width: 90%;
  margin: auto;
  padding-top: 1rem;
  font-size: 0.79rem;
  color: #70521B;
  overflow: hidden;
}

.bottom-cont {
  margin-top: 12vh;

  .prompt-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.86rem;
    color: #333333;
    padding-bottom: 1.3rem;

    .prompt-icon {
      width: 0.93rem;
      margin-right: 0.45rem;
    }
  }

  .order-but {
    width: 25%;
    display: inline-block;
    padding: 0.79rem 0;
    text-align: center;
    background: #FFDEA3;
    border-radius: 2rem;

    .order-icon {
      height: 1.5rem;
    }
  }

  .confirm-but {
    width: 70%;
    float: right;
    display: inline-block;
    background: #FFFFFF;
    border-radius: 2rem;
    font-size: 1.29rem;
    color: #70521B;
    line-height: 1.5rem;
    text-align: center;
    padding: 0.79rem 0;
  }
}


.currency-cont {
  height: 21.25rem;
  background: #E7BD71;
  border-radius: 1.07rem 1.07rem 0rem 0rem;

  .close-icon {
    padding: 1.45rem 1rem;
    text-align: right;

    img {
      width: 1.05rem;
    }
  }

  .lang-list {
    padding: 0 1.03rem 2.5rem 1.03rem;

    .lang-item {
      overflow: hidden;
      padding: 1rem;
      font-size: 1rem;
      color: #333;
      border-bottom: 1px solid #E8E8E7;

      .lang {
        width: 60%;
        float: left;
      }

      .checkbox-cont {
        position: relative;
        width: 30%;
        height: 1.8rem;
        float: right;

        .checkbox {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }

        :deep(.van-checkbox__icon .van-icon) {
          border-color: #fff;
        }

        :deep(.van-checkbox__icon--checked .van-icon) {
          color: #70521B;
        }
      }
    }
  }
}

.set-pwd-cont {
  width: 80vw;
  padding: 1.6rem 6%;
  box-sizing: border-box;
  background: #2D2D2D;
  border-radius: 0.71rem;

  .title {
    text-align: center;
    font-size: 1.14rem;
    margin-bottom: 2.2rem;
    color: #E7BD71;
  }

  .input-cont {
    padding: 0.6rem 0 0.6rem 0.9rem;
    background: #2D2D2D;
    margin-top: 1.3rem;
    border-radius: 0;
    border-bottom: 0.03rem solid #101010;

    input {
      width: 80%;
      border: none;
      background-color: #2D2D2D;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }
  }

  .operate-cont {
    overflow: hidden;

    .operate-but {
      width: 43%;
      height: 3rem;
      line-height: 3rem;
      border: 1px solid #E7BD71;
      border-radius: 1.5rem;
      text-align: center;
      font-size: 1rem;
      color: #E7BD71;
    }

    .ok {
      color: #000000;
      border-color: transparent;
      background: linear-gradient(-90deg, #E8BD70 0%, #EDD185 100%);
    }
  }

  .notes {
    font-size: 0.86rem;
    color: #625F5F;
    padding-top: 1.8rem;
    padding-bottom: 1.5rem;
    text-align: center;
  }

  .error-info {
    height: 1.5rem;
    font-size: 0.86rem;
    color: #E64937;
    line-height: 1.5rem;
  }
}

.show-icon {
  position: relative;
  top: 0.2rem;
  float: right;
  width: 1.43rem;
}

.edit-pwd {
  padding-top: 0.2rem;
  padding-right: 0.3rem;
  float: right;
  font-size: 0.8rem;
  color: #70521B;
}
</style>
